export const authRole = {
  Admin: ['user', 'admin'],
  User: ['user'],
};

export const defaultUser = {
  uid: 'john-alex',
  name: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  avatar: '/assets/images/avatar/A11.jpg',
};
export const allowMultiLanguage = process.env.NX_MULTILINGUAL === 'true';
export const apiUrl = process.env.NX_API_URL as string;
export const frontendBaseUrl = process.env.NX_FRONTEND_BASE_URL as string;
export const backendUrl = process.env.NX_BACKEND_URL as string;
export const fileStackKey = process.env.NX_FILESTACK_KEY as string;
export const initialUrl = process.env.NX_INITIAL_URL as string; // this url will open after login
export const navStyle = process.env.NX_NAV_STYLE as string; // this url will open after login
export const googleTagManagerId = process.env.NX_GOOGLE_TAG_MANAGER_ID as string;
export const backendApi = process.env.NEXT_PUBLIC_BACKEND_API as string;
export const backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST as string;
