import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthUserType } from '@crema/models/AuthUser';
import jwtAxios, { setAuthData, setAuthToken, setAuthUserId } from './index';
import { useInfoViewActionsContext } from '@crema/context/InfoViewContextProvider';
import { AxiosError, isAxiosError } from 'axios';
import {
  AxiosResponseErrors,
  AxiosResponseWithErrors,
  isResponse401Unauthorized,
  isResponse403Forbidden,
  isResponse422UnprocessableContent,
} from '@crema/services/axios';
import Router, { useRouter } from 'next/router';
import { backendUrl } from '@crema/constants/AppConst';
import { useLocaleContext } from '@crema/context/LocaleContextProvider';
import { getDataForLocale } from '../../../../components/src/lib/AppLngSwitcher/data';
import defaultConfig from '@crema/constants/defaultConfig';
import { appIntl, setBrowserLocale } from '@crema/helpers';
import { useCookies } from 'react-cookie';

interface JWTAuthContextProps {
  user: AuthUserType | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
  remember_me: boolean;
}

interface PasswordForgetProps {
  email: string;
}

export interface PasswordResetProps {
  password: string;
  password_confirmation: string;
  token: string;
  email: string;
}

export interface PasswordChangeUserProps {
  user_id: number;
  current_password: string;
  password: string;
  password_confirmation: string;
}

type LoginResponseType = {
  id: number;
  access_token: string;
  expires_in: number;
};

type PasswordUpdateResponseType = {
  success: boolean;
  message: string;
};

export interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps, fieldError: (field: string, message: string | undefined) => void) => void;
  signInUser: (data: SignInProps, fieldError: (field: string, message: string | undefined) => void) => void;
  passwordForgetUser: (
    data: PasswordForgetProps,
    fieldError: (field: string, message: string | undefined) => void,
  ) => void;
  passwordResetUser: (
    data: PasswordResetProps,
    fieldError: (field: string, message: string | undefined) => void,
    assignErrors: { [key: string]: string },
  ) => void;
  // eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
  passwordChangeUser: (
    data: PasswordChangeUserProps,
    fieldError: (field: string, message: string | undefined) => void,
  ) => Promise<any>;
  loadAuthUser: () => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  passwordForgetUser: () => {},
  passwordResetUser: () => {},
  passwordChangeUser: () => Promise.resolve(),
  loadAuthUser: () => {},
  logout: () => {},
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({ children }) => {
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const infoViewActionsContext = useInfoViewActionsContext();
  const { locale } = useLocaleContext();
  const router = useRouter();
  const Intl = appIntl();
  const [cookies, setCookie, removeCookie] = useCookies(['auth-token', 'auth-expires-at']);

  const { pathname, asPath, query } = router;

  const loadAuthUser = async () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token') || cookies['auth-token'];

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
      });
      return;
    }

    setAuthToken(token, 60 * 60 * 24);
    jwtAxios
      .get('/user/profile')
      .then(({ data }: { data: { data: AuthUserType } }) => {
        setAuthUserId(data.data.id, 60 * 60 * 24);

        if (!(localStorage.getItem('token') || sessionStorage.getItem('token')) && cookies['auth-token']) {
          // missing in session storage
          console.warn('Promote token to session?');
        }

        setJWTAuthData({
          user: data.data,
          isLoading: false,
          isAuthenticated: true,
        });

        // console.log('profile locale: ', data.data.locale);

        // console.log('locale data', {
        //   profile: data.data.locale,
        //   locale_locale: locale.locale,
        //   default: defaultConfig.locale.locale,
        // });

        // if (data.data.locale !== getBrowserLocale()) {
        if (data.data.locale !== locale.locale && locale.locale === defaultConfig.locale.locale) {
          // writeToStorage('locale', data.data.locale);

          // const {locale} = useLocaleContext();
          // const { updateLocale } = useLocaleActionsContext();

          const localeValid = getDataForLocale(data.data.locale);
          // console.log('localeValid from profile:', localeValid);
          if (localeValid) {
            // router.push({pathname, query}, asPath, {locale: language.locale});
            // updateLocale(localeValid);

            router.push({ pathname, query }, asPath, {
              locale: data.data.locale,
            });
          }
        }
      })
      .catch((error: any) => {
        if (isAxiosError(error) && error.response?.status === 401) {
          localStorage.removeItem('token');
          sessionStorage.removeItem('token');
          removeCookie('auth-token');
          setAuthToken();
          setAuthUserId();
        }

        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
      });
  };

  useEffect(() => {
    loadAuthUser();
  }, []);

  const fieldErrors = (
    errors: AxiosError,
    setFieldError: (field: string, message: string | undefined) => void,
    assignErrors: { [key: string]: string } = {},
  ) => {
    const response = errors.response as unknown as AxiosResponseWithErrors;
    const dataErrors = response.data.errors as AxiosResponseErrors;

    for (const fieldKey in response.data.errors) {
      const errorMessages = dataErrors[fieldKey];

      if (assignErrors && assignErrors[fieldKey] !== undefined) {
        setFieldError(assignErrors[fieldKey], errorMessages[0]);
      } else {
        setFieldError(fieldKey, errorMessages[0]);
      }

      console.log('fieldKey', fieldKey, errorMessages[0]);
    }
  };

  const signInUser = async (
    {
      email,
      password,
      remember_me,
    }: {
      email: string;
      password: string;
      remember_me: boolean;
    },
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      await getCsrfToken();
      const { data } = await jwtAxios.post<LoginResponseType>('/user/login', {
        email,
        password,
        remember_me,
      });

      if (remember_me) {
        localStorage.setItem('token', data.access_token);
        sessionStorage.removeItem('token');
      } else {
        sessionStorage.setItem('token', data.access_token);
        localStorage.removeItem('token');
      }

      if (data) {
        setAuthData(data.access_token, data.id, data.expires_in);
      }

      const res = await jwtAxios.get('/user/profile');
      // console.log('Profile data', res.data);

      // update locale from previous session
      setBrowserLocale(res.data.data.locale);

      setJWTAuthData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });

      // setAuthUserId(res.data.data.id, data.expires_in);
      // setAuthData(data.access_token, res.data.data.id, data.expires_in);

      infoViewActionsContext.fetchSuccess();
    } catch (error: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(error)) {
        if (isResponse401Unauthorized(error)) {
          setFieldError('password', 'Invalid email or password');
          infoViewActionsContext.clearInfoView();
        } else if (
          isResponse403Forbidden(error) &&
          error.response.data.message === 'Your email address is not verified.'
        ) {
          setFieldError('password', 'Email not verified. Check your email for activation code');
          infoViewActionsContext.clearInfoView();
        } else if (isResponse422UnprocessableContent(error)) {
          fieldErrors(error, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else if (error.code === 'ERR_NETWORK') {
          infoViewActionsContext.fetchError('There are problems reaching the api. Check your internet connection.');
        } else {
          console.log('error', error);
          infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Error not handled' }));
        }
      } else {
        infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
      }
    }
  };

  const signUpUser = async (
    {
      name,
      email,
      password,
    }: {
      name: string;
      email: string;
      password: string;
    },
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('/user/register', {
        name,
        email,
        password,
      });
      if (data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      console.log('Register data', data);

      const { data: loginData } = await jwtAxios.post<LoginResponseType>('/user/login', {
        email,
        password,
      });

      sessionStorage.setItem('token', loginData.access_token);
      localStorage.removeItem('token');

      // setAuthToken(loginData.access_token, loginData.expires_in);
      setAuthData(loginData.access_token, loginData.id, data.expires_in);

      const res = await jwtAxios.get('/user/profile');
      setJWTAuthData({
        user: res.data.data,
        isAuthenticated: true,
        isLoading: false,
      });

      // setAuthUserId(res.data.data.id, loginData.expires_in);
      // setAuthData(loginData.access_token, res.data.data.id, loginData.expires_in);

      infoViewActionsContext.fetchSuccess();
    } catch (err: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
        }
      } else {
        infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
      }
    }
  };

  const passwordForgetUser = async (
    {
      email,
    }: {
      email: string;
    },
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post('/user/forgot', { email });
      if (data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      console.log('Forget data', data);

      infoViewActionsContext.showMessage(data.message);
    } catch (err: unknown) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
        }
      } else {
        infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
      }
    }
  };

  const passwordResetUser = async (
    { token, email, password, password_confirmation }: PasswordResetProps,
    setFieldError: (field: string, message: string | undefined) => void,
    assignErrors: {
      [key: string]: string;
    },
  ) => {
    infoViewActionsContext.fetchStart();

    try {
      const passwordUpdateResponse = await jwtAxios
        .post<PasswordUpdateResponseType>('/user/password-update', {
          token,
          email,
          password,
          password_confirmation,
        })
        .catch((err: unknown) => {
          console.log('err in post', err);

          if (isAxiosError(err)) {
            if (isResponse422UnprocessableContent(err)) {
              fieldErrors(err, setFieldError);
              infoViewActionsContext.clearInfoView();
            } else {
              infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
            }
          } else {
            infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
          }

          return null;
        });

      if (passwordUpdateResponse === null || passwordUpdateResponse.data.success !== true) {
        throw new Error('Something went wrong 1');
      }

      infoViewActionsContext.showMessage(passwordUpdateResponse.data.message);

      Router.push('/signin');
    } catch (err: unknown) {
      console.log('err', err);

      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });

      if (isAxiosError(err)) {
        if (isResponse401Unauthorized(err)) {
          infoViewActionsContext.clearInfoView();
        } else if (isResponse422UnprocessableContent(err)) {
          fieldErrors(err, setFieldError, assignErrors);
          infoViewActionsContext.clearInfoView();
        } else {
          infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
        }
      } else {
        infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
      }
    }
  };

  const passwordChangeUser = async (
    { user_id, current_password, password, password_confirmation }: PasswordChangeUserProps,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    return new Promise((resolve, reject) => {
      try {
        jwtAxios
          .post(`/user/${user_id}/password-change`, {
            current_password,
            password,
            password_confirmation,
          })
          .then((data: any) => {
            if (data.data.success === true) {
              resolve(true);
            }

            reject(data.data);
          })
          .catch((err: unknown) => {
            // console.log('err in post', err);
            // resolve(err);

            if (isAxiosError(err)) {
              if (isResponse422UnprocessableContent(err)) {
                fieldErrors(err, setFieldError);
                infoViewActionsContext.clearInfoView();
              } else {
                infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
              }
            } else {
              infoViewActionsContext.fetchError(Intl.formatMessage({ id: 'Something went wrong' }));
            }

            reject(false);
            // reject(err);
          });
      } catch (err: unknown) {
        console.log('err', err);
        reject(err);
      }
    });
  };

  const logout = async () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    // email verification notification
    sessionStorage.removeItem('verify_show_notice');
    removeCookie('auth-token');
    removeCookie('auth-expires-at');
    setAuthToken();
    setAuthUserId();
    setAuthData();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const getCsrfToken = async () => {
    try {
      const { data } = await jwtAxios.get(backendUrl + 'sanctum/csrf-cookie');
      return data;
    } catch (err: unknown) {
      return false;
    }
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          passwordForgetUser,
          passwordResetUser,
          passwordChangeUser,
          loadAuthUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
